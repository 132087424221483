import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  PaymentModalState,
  alertModalState,
  cashBillModalState, paymentDoneModalState,
} from "../../utils/atom";
import ModalWrapper from "./ModalWrapper";
import Button from "../elements/Button";
import { Dot } from "../../utils/CommonFunction";
import { ReactComponent as Card } from "../../assets/icons/card-icon.svg";
import { ReactComponent as Cash } from "../../assets/icons/cash-icon.svg";
import {api} from "../../utils/api";

const PaymentModal = () => {
  const [modal, setModal] = useRecoilState(PaymentModalState);
  const setCashBillModal = useSetRecoilState(cashBillModalState);
  const setDoneModal = useSetRecoilState(paymentDoneModalState);

  const handleClose = () => {
    setModal(null);
  };

  const handlePayment = async () => {
    const formData = new FormData();
    // @ts-ignore
    formData.append("discount_price", modal?.discount?.toString());
    // @ts-ignore
    JSON.parse(localStorage.getItem("order"))?.map((menu, i) => {
        formData.append(`pos_product_ids[${i}][id]`, menu?.id);
        formData.append(`pos_product_ids[${i}][amount]`, menu?.amount);
    });

    const {
      data: { success, alert, data: data2 },
    } = await api.post("/order", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    if (success) {
      if (data2?.payment_price === 0) {
        handleClose();
      } else {
        window.rayno.payment(localStorage.getItem("token"), data2?.id, "00")
        handleClose();
      }
    } else {
      window.alert(alert);
    }
  };

  if (modal === null) return null;

  return (
    <ModalWrapper onClose={handleClose}>
      <Wrapper>
        <Title>결제하기</Title>
        <TypeArea>
          <TypeBox
            onClick={() => {
              handlePayment()
              /*setAlertModal({
                title: "결제하기",
                text: "보안 IC 카드정보를 요청하고 있습니다.<br/>잠시만 기다려주세요.",
                confirmText: "취소",
                price: modal?.total - modal?.discount,
                onConfirm: () => {},
                onDone: modal?.onDone,
              });*/
            }}
          >
            <Card />
            <p>신용/체크카드</p>
          </TypeBox>
          <TypeBox
            onClick={() => {
              handleClose();
              setCashBillModal({ onDone: modal?.onDone });
            }}
          >
            <Cash />
            <p>현금 결제</p>
          </TypeBox>
        </TypeArea>
        <Seperate />
        <Price>
          총 결제금액
          <p>
            {Dot(modal?.total - modal?.discount)}
            <span>원</span>
          </p>
        </Price>
        <Button styleType="line" onClick={handleClose}>
          취소
        </Button>
      </Wrapper>
    </ModalWrapper>
  );
};

export default PaymentModal;

const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.01em;
  color: rgba(255, 255, 255, 0.6);

  & > p {
    font-size: 13px;
    font-weight: 500;
    line-height: 19.5px;
    color: white;

    & > span {
      font-size: 10px;
      line-height: 15px;
      margin-left: 2px;
    }
  }
`;

const Seperate = styled.div`
  margin: 30px 0 16px;
  width: 100%;
  height: 1px;
  background: ${(props) => props.theme.color.line};
`;

const TypeBox = styled.div`
  width: calc(50% - 5px);
  border-radius: 14px;
  border: solid 1px ${(props) => props.theme.color.gray4};
  padding: 25px 10px 18px;

  & > svg {
    width: 30px;
    height: 30px;
    margin: 0 auto 14px;
    display: block;
  }

  & > p {
    font-size: 13px;
    font-weight: 600;
    line-height: 13px;
    text-align: center;
    color: rgba(255, 255, 255, 0.9);
  }
`;

const TypeArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: white;
  margin-bottom: 18px;
`;

const Wrapper = styled.div`
  min-width: 284px;
  width: 284px;
  padding: 22px;
  background: ${(props) => props.theme.color.gray3};
  border-radius: 18px;
  border: solid 1px ${(props) => props.theme.color.gray4};
`;
